















import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import OutOfCreditsMessage from '~/components/car/alert/credit/messages/OutOfCreditsMessage.vue'
import LowCreditsMessage from '~/components/car/alert/credit/messages/LowCreditsMessage.vue'
import { hideCreditsOverCookieName } from '~/constants/user/credit'
import { ONE_DAY } from '~/constants/duration'
import { computed, defineComponent } from '@nuxtjs/composition-api'
import { useNamespacedStore } from '~/compositions/store'
import CookiesService from '~/services/CookiesService'
import { useDep } from '~/compositions/dependency-container'

export default defineComponent({
  components: { OutOfCreditsMessage, LowCreditsMessage },
  setup() {
    const { getters: userGetters } = useNamespacedStore<UserState>(USER_NS)
    const cookiesService = useDep(CookiesService)

    const showLowCreditsMessage = computed(() =>
      userGetters('showLowCreditsMessage')
    )
    const showOutOfCreditsMessage = computed(() =>
      userGetters('showOutOfCreditsMessage')
    )

    const show = computed(() => {
      const hasHideCookie = cookiesService.get(hideCreditsOverCookieName)

      return (
        !hasHideCookie &&
        (showLowCreditsMessage.value || showOutOfCreditsMessage.value)
      )
    })

    function handleDismiss() {
      cookiesService.set(hideCreditsOverCookieName, 1, { maxAge: ONE_DAY })
    }

    return {
      handleDismiss,
      show,
      showLowCreditsMessage,
      showOutOfCreditsMessage
    }
  }
})
