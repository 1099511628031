



















import { capitalizeAllWords } from '~/utils/string'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  computed: {
    btnText() {
      return `"${capitalizeAllWords(this.$t('reset password') as string)}"`
    }
  }
})
