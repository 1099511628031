





























































































import {
  ref,
  defineComponent,
  useRouter,
  useFetch,
  computed
} from '@nuxtjs/composition-api'
import { useDeps } from '~/compositions/dependency-container'
import { useI18n } from '~/compositions/i18n'
import { useNamespacedStore } from '~/compositions/store'
import { useCookies } from '~/compositions/useCookies'
import { useAnalytics } from '~/compositions/analytics'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import RouteGuardService from '~/services/RouteGuardService'
import {
  HeaderDropdownLink,
  HeaderDropdown as HeaderDropdownInterface,
  HeaderDropdownLinkClickEvent,
  DropdownPosition
} from '~/models/header/header-dropdown'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import {
  ADMINUSER_NS,
  AdminUserState
} from '~/store/modules/shared/admin/user/state'
import { ANON_GUARD_COOKIE } from '~/constants/anon-guard'
import { ciUserCircle as ciUserCircleRegular } from '~/icons/source/regular/user-circle'
import { ciUserCircle as ciUserCircleSolid } from '~/icons/source/solid/user-circle'
import { ciFolderOpen } from '~/icons/source/regular/folder-open'
import { ciParking } from '~/icons/source/regular/parking'
import { ciParkingP } from '~/icons/source/solid/parking-p'
import { ciHamburger } from '~/icons/source/regular/hamburger'
import { ciArrowAltLeft } from '~/icons/source/solid/arrow-alt-left'
import Logo from '~/components/car/header/Logo.vue'
import HeaderDropdownComponent from '~/components/car/header/HeaderDropdown.vue'
import CLink from '~/components/shared/configurable/link/CLink.vue'
import CIcon from '~/components/shared/configurable/CIcon.vue'
import CDynamicIcon from '~/components/shared/configurable/icon/CDynamicIcon.vue'
import HeaderParking from '~/components/car/header/HeaderParking.vue'
import { CategoryId } from '~/models/category/types'
import { defineComponentTranslations } from '~/utils/i18n'
import HeaderUserIcon from '~/components/car/header/HeaderUserIcon.vue'
import { AccessToggleName } from '~/models/user/access-toggles'
import { useUserAgent } from '~/compositions/user-agent'
import HeaderCart from '~/components/car/header/HeaderCart.vue'

// NOTE: base api here -> https://www.car.gr/api/user/base-page/ user_base_page
// TODOs:
// - Parking and message notifications test with mock data

export default defineComponent({
  components: {
    Logo,
    HeaderDropdownComponent,
    CLink,
    CIcon,
    CDynamicIcon,
    HeaderParking,
    HeaderUserIcon,
    HeaderCart,
    AdminTopBar: () => import('~/components/shared/header/AdminTopBar.vue'),
    CarMobileMenu: () => import('~/components/car/header/CarMobileMenu.vue'),
    UserDropdown: () => import('~/components/car/header/UserDropdown.vue'),
    CAnonGuardNewClassifiedModal: () =>
      import(
        '~/components/shared/configurable/user/CAnonGuardNewClassifiedModal.vue'
      ),
    CExternalLinkMediatorModal: () =>
      import(
        '~/components/shared/configurable/modal/CExternalLinkMediatorModal.vue'
      )
  },
  props: {
    availableLocales: {
      type: Array,
      required: true
    }
  },
  setup() {
    const router = useRouter()
    const { t } = useI18n()
    const cookies = useCookies()
    const analytics = useAnalytics()
    const [legacyUrlService, routeGuardService] = useDeps(
      LegacyUrlService,
      RouteGuardService
    )
    const { getters: userGetters } = useNamespacedStore<UserState>(USER_NS)
    const { state: adminUserState } = useNamespacedStore<AdminUserState>(
      ADMINUSER_NS
    )

    const showMobileMenu = ref(false)
    const showExternalLinkMediatorModal = ref(false)
    const externalLinkMediatorUrl = ref('')
    const anonGuardModalVisible = ref(false)
    const anonGuardModalContinueUrl = ref('')
    const menu = ref<{ [key: string]: HeaderDropdownInterface }>({
      search: {
        title: t('search'),
        links: [] as HeaderDropdownLink[][],
        dropdownPosition: DropdownPosition.CENTER
      },
      offer: {
        title: t('offer'),
        links: [] as HeaderDropdownLink[][],
        dropdownPosition: DropdownPosition.CENTER,
        hide: !routeGuardService.userCanCreateClassified()
      },
      services: {
        title: t('services'),
        links: [] as HeaderDropdownLink[][],
        dropdownPosition: DropdownPosition.CENTER
      },
      information: {
        title: t('information'),
        subtitles: [t('general'), t('dealers'), t('private sellers')],
        links: [] as HeaderDropdownLink[][],
        dropdownPosition: DropdownPosition.CENTER
      }
    })

    const { isPc } = useUserAgent()

    const isAdmin = userGetters('isAdmin')
    const isAnon = userGetters('isSingleOrAnon')
    const userHasAccessTo = userGetters('userHasAccessTo')

    const adminExtras = adminUserState.adminExtras
    const hasAccessToParking = routeGuardService.userHasAccessToParking()
    const userRegisterLink = legacyUrlService.getRegisterUrl()
    const offerBtnLink = legacyUrlService.offerBtnLink()
    const offerBtnText = legacyUrlService.offerBtnText()
    const offerBtnIcon = legacyUrlService.offerBtnIcon(!isPc.value)

    const cartItemsCount = computed(() =>
      userGetters('marketplaceCartItemsCount')
    )

    const showCartButton = computed(() => {
      return cartItemsCount.value > 0
    })

    const linkClicked = (
      dropdownLinkClickEvent: HeaderDropdownLinkClickEvent
    ) => {
      const { link, event } = dropdownLinkClickEvent

      // Analytics tracking
      analytics.recordEvent({
        namespace: 'n_header_link_desktop',
        label: link.url,
        action: 'click'
      })

      // Handle external links with modal
      if (link.externalLink && event) {
        event.preventDefault()
        externalLinkMediatorUrl.value = link.url
        showExternalLinkMediatorModal.value = true
        return
      }

      // Handle AnonGuard links with modal
      if (link.useAnonGuard && isAnon && !cookies.get(ANON_GUARD_COOKIE)) {
        event.preventDefault()
        anonGuardModalContinueUrl.value = link.url
        anonGuardModalVisible.value = true
      }
    }

    const populateSubMenus = async () => {
      const [
        ciVehicles,
        ciPartsAccessories,
        ciRealEstate,
        ciXyma,
        ciJobs,
        ciVehicleRentals,
        ciDeals,
        ciCarService,
        ciSearch,
        ciCar,
        ciMotorcycle,
        ciTruck,
        ciCaravan,
        ciBoat,
        ciBicycle,
        ciRc,
        ciWantedParts
      ] = await Promise.all([
        import('~/icons/source/duotone/vehicles').then(m => m.ciVehicles),
        import('~/icons/source/duotone/parts-accessories').then(
          m => m.ciPartsAccessories
        ),
        import('~/icons/source/duotone/real-estate').then(m => m.ciRealEstate),
        import('~/icons/source/duotone/xyma').then(m => m.ciXyma),
        import('~/icons/source/duotone/jobs').then(m => m.ciJobs),
        import('~/icons/source/brand/vehicle-rentals').then(
          m => m.ciVehicleRentals
        ),
        import('~/icons/source/duotone/deals').then(m => m.ciDeals),
        import('~/icons/source/duotone/car-service').then(m => m.ciCarService),
        import('~/icons/source/duotone/search').then(m => m.ciSearch),
        import('~/icons/source/duotone/car').then(m => m.ciCar),
        import('~/icons/source/duotone/motorcycle').then(m => m.ciMotorcycle),
        import('~/icons/source/duotone/truck').then(m => m.ciTruck),
        import('~/icons/source/duotone/caravan').then(m => m.ciCaravan),
        import('~/icons/source/duotone/boat').then(m => m.ciBoat),
        import('~/icons/source/duotone/bicycle').then(m => m.ciBicycle),
        import('~/icons/source/duotone/rc').then(m => m.ciRc),
        import('~/icons/source/duotone/wanted-parts').then(m => m.ciWantedParts)
      ])

      menu.value.search.links = [
        [
          {
            title: t('vehicles'),
            icon: ciVehicles,
            url: router.resolve({ name: '__classifieds_quick_search' }).href,
            useNuxtLink: true
          },
          {
            title: `${t('parts')} & ${t('accessories')}`,
            icon: ciPartsAccessories,
            url: '/quick-search/parts/for-cars/',
            useNuxtLink: true
          },
          {
            title: t('wanted_parts'),
            icon: ciWantedParts,
            url: router.resolve({
              name: '__classifieds_search_wanted_parts',
              params: { categoryId: CategoryId.WANTED_PARTS as string }
            }).href,
            useNuxtLink: true,
            useAnonGuard: true,
            new_label: true,
            hide: !userHasAccessTo(AccessToggleName.WANTED_PARTS_ACCESS)
          },
          {
            title: `${t('real estate')} - Plot.gr`,
            icon: ciRealEstate,
            url: router.resolve({ name: '__quick_search_plot' }).href,
            useNuxtLink: true
          },
          {
            title: t('classifieds::xyma'),
            icon: ciXyma,
            url: router.resolve({ name: '__classifieds_search_xyma' }).href,
            useNuxtLink: true
          },
          {
            title: t('jobs'),
            icon: ciJobs,
            url: router.resolve({ name: '__classifieds_search_jobs' }).href,
            useNuxtLink: true
          },
          {
            title: t('rentals'),
            icon: ciVehicleRentals,
            url: router.resolve({ name: '__quick_search_rentals' }).href,
            useNuxtLink: true
          },
          {
            title: t('car_service'),
            icon: ciCarService,
            url: '/quick-search/service/',
            useNuxtLink: true
          },
          {
            title: t('deals'),
            icon: ciDeals,
            url: router.resolve({ name: '__classifieds_search_deals' }).href,
            useNuxtLink: true
          },
          {
            title: t('search classified with id'),
            icon: ciSearch,
            url: router.resolve({ name: '__search_classified_by_id' }).href,
            useNuxtLink: true
          }
        ]
      ]

      menu.value.offer.links = [
        [
          {
            title: t('car::vehicles'),
            icon: ciCar,
            url: '/classifieds/cars/new/',
            useNuxtLink: false,
            useAnonGuard: true
          },
          {
            title: t('bike::vehicles'),
            icon: ciMotorcycle,
            url: '/classifieds/bikes/new/',
            useNuxtLink: false,
            useAnonGuard: true
          },
          {
            title: t('commercial::vehicles'),
            icon: ciTruck,
            url: '/classifieds/commercial/new/',
            useNuxtLink: true,
            useAnonGuard: true
          },
          {
            title: t('camper::vehicles'),
            icon: ciCaravan,
            url: '/classifieds/camper/new/',
            useNuxtLink: true,
            useAnonGuard: true
          },
          {
            title: t('boat::vehicles'),
            icon: ciBoat,
            url: router.resolve({
              name: '__classifieds_boats_new_form',
              params: { categoryId: CategoryId.BOATS as string }
            }).href,
            useNuxtLink: true
          },
          {
            title: t('bicycle::vehicles'),
            icon: ciBicycle,
            url: router.resolve({
              name: '__classifieds_bicycles_new_form',
              params: { categoryId: CategoryId.BICYCLES as string }
            }).href,
            useNuxtLink: true
          },
          {
            title: t('hobby::vehicles'),
            icon: ciRc,
            url: '/classifieds/hobby/new/',
            useNuxtLink: false,
            useAnonGuard: true
          }
        ],
        [
          {
            title: `${t('part')}/${t('accessory')}`,
            icon: ciPartsAccessories,
            url: '/parts/new/',
            useNuxtLink: false,
            useAnonGuard: true
          },
          {
            title: t('wanted_parts'),
            icon: ciWantedParts,
            url: router.resolve({
              name: '__classifieds_wanted_parts_new_form',
              params: { categoryId: CategoryId.WANTED_PARTS as string }
            }).href,
            useNuxtLink: true,
            useAnonGuard: true,
            new_label: true,
            hide: !userHasAccessTo(AccessToggleName.WANTED_PARTS_ACCESS)
          },
          {
            title: t('rent'),
            icon: ciVehicleRentals,
            url: router.resolve({
              name: '__classifieds_rentals_new_form',
              params: { categoryId: CategoryId.RENTALS as string }
            }).href,
            useNuxtLink: true
          },
          {
            title: `${t('real estate::akinito')} - Plot.gr`,
            icon: ciRealEstate,
            url: 'https://www.plot.gr/classifieds/new?vehuser=true',
            externalLink: true,
            useNuxtLink: false
          },
          {
            title: t('classified::xyma'),
            icon: ciXyma,
            url: '/xyma/new/',
            useNuxtLink: false,
            useAnonGuard: true
          },
          {
            title: t('job'),
            icon: ciJobs,
            url: router.resolve({
              name: '__classifieds_jobs_new_form',
              params: { categoryId: CategoryId.JOBS as string }
            }).href,
            useNuxtLink: true
          }
        ]
      ]

      menu.value.services.links = [
        [
          {
            title: t('classifieds promotion'),
            url: router.resolve({ name: '__landing_pages_promotions' }).href,
            useNuxtLink: true
          },
          { title: t('advertise in car.gr'), url: '/advertising/' },
          {
            title: t('audits'),
            url: router.resolve({ name: '__landing_pages_audits' }).href,
            useNuxtLink: true
          },
          { title: t('car_service'), url: '/quick-search/service/' },
          {
            title: t('finance online'),
            url: router.resolve({ name: '__landing_pages_tbi' }).href,
            useNuxtLink: true
          }
        ],
        [
          {
            title: t('questions and answers'),
            url: router.resolve({ name: '__qna_view' }).href,
            useNuxtLink: true
          },
          {
            title: t('trades'),
            url: router.resolve({ name: '__trades' }).href,
            useNuxtLink: true
          },
          {
            title: t('dealers catalogue'),
            url: '/dealers/',
            useNuxtLink: true
          },
          {
            title: t('catalog of businesses'),
            url: '/cartalog/categories/',
            useNuxtLink: false
          },
          {
            title: t('used car prices'),
            url: '/classifieds/cars/prices/',
            useNuxtLink: false
          }
        ]
      ]

      menu.value.information.links = [
        [
          {
            title: t('support'),
            url: router.resolve({ name: '__information_contact' }).href,
            useNuxtLink: true
          },
          {
            title: t('market guide'),
            url: router.resolve({ name: '__information_simboulos' }).href,
            useNuxtLink: true
          },
          {
            title: t('terms and conditions'),
            url: router.resolve({ name: '__information_terms' }).href,
            useNuxtLink: true
          },
          {
            title: t('privacy policy'),
            url: router.resolve({ name: '__information_privacy' }).href,
            useNuxtLink: true
          },
          {
            title: t('job offers'),
            url: router.resolve({ name: '__information_careers' }).href,
            useNuxtLink: true
          },
          {
            title: 'Blog - Car.gr',
            url: 'https://blog.car.gr/',
            useNuxtLink: false
          }
        ],
        [
          {
            title: t('dealer registration'),
            url: '/dealers/register/',
            useNuxtLink: false
          },
          {
            title: t('register your business'),
            url: '/cartalog/register/',
            useNuxtLink: false
          },
          {
            title: t('dealers frequently asked questions'),
            url: router.resolve({ name: '__information_dealers_faq' }).href,
            useNuxtLink: true
          }
        ],
        [
          {
            title: t('free user registration'),
            url: userRegisterLink,
            useNuxtLink: false
          },
          {
            title: t('users frequently asked questions'),
            url: router.resolve({ name: '__information_users_faq' }).href,
            useNuxtLink: true
          }
        ]
      ]

      menu.value = { ...menu.value }
    }

    useFetch(populateSubMenus)

    return {
      ciUserCircleRegular,
      ciUserCircleSolid,
      ciFolderOpen,
      ciParking,
      ciParkingP,
      ciHamburger,
      ciArrowAltLeft,
      showMobileMenu,
      showExternalLinkMediatorModal,
      externalLinkMediatorUrl,
      anonGuardModalVisible,
      anonGuardModalContinueUrl,
      menu,
      isPc,
      userRegisterLink,
      offerBtnLink,
      offerBtnText,
      offerBtnIcon,
      hasAccessToParking,
      isAdmin,
      isAnon,
      adminExtras,
      showCartButton,
      linkClicked
    }
  },
  i18n: defineComponentTranslations({
    general: {
      en: 'General',
      el: 'Γενικά'
    },
    'finance online': {
      en: 'Online finance',
      el: 'Χρηματοδοτήσεις online'
    }
  })
})
